<template>
  <SubNav
    class="subnav-position"
    :selected="'spaces'"
    :buildingId="buildingId"
  />
  <main>
    <div v-if="spaces && spaces.length > 0" class="subnav-detail">
      <div class="d-flex flex-row-reverse">
        <router-link
          class="btn btn-primary"
          :to="{ name: 'CreateSpace', params: { buildingId: buildingId } }"
        >
          Create Space</router-link
        >
      </div>

      <div v-for="space in spaces" :key="space.id">
        <router-link
          :to="{
            name: 'EditSpace',
            params: { spaceId: space.id }
          }"
        >
          <div class="row single">
            <div class="col">
              <div class="building-thumb">
                <img :src="space.floorPlanURLThumb" />
              </div>
            </div>
            <div class="col">
              {{ space.name }}
            </div>
            <div class="col">
              {{ space.suiteNumber }}
            </div>
            <div class="col">
              <div v-if="space.available">Showing Available</div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div v-else class="subnav-detail">
      <div class="d-flex flex-row-reverse">
        <router-link
          class="btn btn-primary"
          :to="{ name: 'CreateSpace', params: { buildingId: buildingId } }"
        >
          Create Space</router-link
        >
      </div>
    </div>
    <div class="error">{{ errorSpace }}</div>
    <div v-for="space in spaces" :key="space.id"></div>
  </main>
</template>

<script>
import SubNav from "@/components/navigation/SubNav.vue";
import getCollection from "@/composables/getCollection";
import getUser from "@/composables/getUser";

export default {
  props: ["buildingId"],
  components: { SubNav },
  setup(props) {
    const { user } = getUser();
    const { error: errorSpace, documents: spaces } = getCollection(
      "spaces",
      [
        ["managerId", "==", user.value.uid],
        ["buildingId", "==", props.buildingId]
      ],
      [["name", "asc"]]
    );

    return { errorSpace, spaces };
  }
};
</script>

<style>
</style>